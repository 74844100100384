<template>
  <div class="service">

    <div class="main">
      <div class="main-breadcrumb p-2">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item class="cursor" :to="{ path: '/JinChangJiaoYiList' }">进场交易</el-breadcrumb-item>
          <el-breadcrumb-item>项目详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <!-- 项目基本情况-->
      <el-divider></el-divider>

      <el-descriptions class="margin-top descriptions-style" :column="3" :size="medium" border>
        <div slot="title" class="descriptions-style-title">
          {{ form.targetName }}
          <el-button v-if="form.state == 0" @click="goLink(form.openUrl)"
                     type="primary" plain icon="el-icon-right" size="small">项目入口
          </el-button>
        </div>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            项目名称
          </template>
          {{ form.targetName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            项目编号
          </template>
          {{ form.projectno }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            转让方
          </template>
          {{ form.disposalenterpriseName }}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-tickets"></i>
            转让价格
          </template>
          <el-tag size="medium">{{ form.overprice }}万元</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            项目状态
          </template>
          <el-tag size="small">
            {{ getDictLabel (form.state) }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item span="2">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            标的物所在地
          </template>
          {{ form.areacode }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            所属行业
          </template>
          {{ form.industryInvolved }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            交易机构
          </template>
          {{ form.mechanismExchange }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            委托会员
          </template>
          {{ form.entrustedMember }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            披露开始时间
          </template>
          {{ form.disclosureStartTime }}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            披露结束时间
          </template>
          {{ form.disclosureEndTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            联系人姓名
          </template>
          {{ form.lxrName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            联系人电话
          </template>
          {{ form.lxrPhone }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 标的信息-->
      <el-divider></el-divider>
      <el-descriptions v-if="form.entryType == '1'" class="margin-top descriptions-style" title="标的信息" :column="3" :size="medium"
                       border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            注册地
          </template>
          {{ form.registration }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            法定代表人
          </template>
          {{ form.legal }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            注册资本
          </template>
          {{ form.situation }}万元
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-tickets"></i>
            成立日期
          </template>
          {{ form.establishmentTime }}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            经济类型
          </template>
          {{ form.economicType }}
        </el-descriptions-item>
        <el-descriptions-item span="2">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            组织机构代码
          </template>
          {{ form.code }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            经营范围
          </template>
          {{ form.businessScope }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            其他股东是否放弃优先受让权
          </template>
          {{ form.preferential }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            是否涉及职工安置
          </template>
          {{ form.placement }}
        </el-descriptions-item>
        <el-descriptions-item :span="3">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            其他披露内容
          </template>
          <div v-html="form.disclosure"></div>
        </el-descriptions-item>
        <template v-for="(item, index) in form.shareList">
          <template>
            <el-descriptions-item :key="index" :span="2">
              <template slot="label">
                <i class="el-icon-office-building"></i>
                股东名称
              </template>
              {{ item.name }}
            </el-descriptions-item>
            <el-descriptions-item :key="index">
              <template slot="label">
                <i class="el-icon-office-building"></i>
                持股比例
              </template>
              {{ item.ratio }}%
            </el-descriptions-item>
          </template>
        </template>

      </el-descriptions>
      <el-descriptions v-else class="margin-top descriptions-style" title="标的信息" :column="3" :size="medium" border>
        <el-descriptions-item :span="3">
          <template slot="label">
            <i class="el-icon-s-comment"></i>
            标的描述
          </template>
          <div v-html="form.entrydescribe"></div>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-s-check"></i>
            标的是否有租赁
          </template>
          {{ form.lease }}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-s-check"></i>
            标的抵押/质押情况
          </template>
          {{ form.mortgage }}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-tickets"></i>
            是否存在其他方占用情况
          </template>
          {{ form.occupy }}
        </el-descriptions-item>
        <el-descriptions-item :span="3">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            权利人是否行使优先权
          </template>
          {{ form.priority }}
        </el-descriptions-item>
        <el-descriptions-item :span="3">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            文件列表
          </template>
          <div v-for="(item, index) in fileList" style="margin-bottom: 10px;" :key="index">
            <a :href="myurl(item.url)" >{{ item.name }}</a>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <!-- 主要财务数据，垂直边框 -->
      <el-descriptions v-if="form.entryType == '1'" class="descriptions-style" direction="vertical" border>
        <el-descriptions-item label="主要财务数据">
          <p
              v-html="form.financial"
              class="chengnuohan1"
              style="height: 100%; overflow-x: hidden"
          ></p>
        </el-descriptions-item>
      </el-descriptions>
      <!-- 转让方信息 -->
      <el-divider></el-divider>
      <el-descriptions class="margin-top descriptions-style" title="转让方信息" :column="3" :size="medium" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            转让方名称
          </template>
          {{ form.transferorName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            注册地
          </template>
          {{ form.domicile }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            法定代表人
          </template>
          {{ form.representative }}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-tickets"></i>
            注册资本
          </template>
          {{ form.registeredCapital }} 万元
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            成立日期
          </template>
          {{ form.transferorTime }}
        </el-descriptions-item>
        <el-descriptions-item span="2">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            经济类型
          </template>
          {{ form.transferorEconomicType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            转让方所属行业
          </template>
          {{ form.transferorInvolved }}
        </el-descriptions-item>
        <template v-if="form.entryType == '1'">
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              转让方组织机构代码
            </template>
            {{ form.transferorCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              转让方持股比例
            </template>
            {{ form.transferorRatio }}%
          </el-descriptions-item>
          <el-descriptions-item :span="3">
            <template slot="label">
              <i class="el-icon-office-building"></i>
              拟转让产（股）权比例
            </template>
            {{ form.property }}%
          </el-descriptions-item>
        </template>

      </el-descriptions>
      <!-- 交易条件 -->
      <el-divider></el-divider>
      <el-descriptions class="margin-top descriptions-style" title="交易条件" :column="3" :size="medium" border>
        <el-descriptions-item :span="2">
          <template slot="label">
            <i class="el-icon-user"></i>
            转让低价
          </template>
          {{ form.transferReservePrice }}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            保证金
          </template>
          {{ form.earnestMoney }} 万元
        </el-descriptions-item>
        <el-descriptions-item :span="3">
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            价款支付方式
          </template>
          <div v-html="form.payment"></div>
        </el-descriptions-item>
        <!-- 实物资产 -->
        <template v-if="Number(form.entryType) == Number('0')">
          <el-descriptions-item v-if="form.entryType == '1'" :span="2">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              标的企业股权结构
            </template>
            <div v-for="(item, index) in form.shareList" :key="index" style="margin-bottom: 10px;">
              {{item.name}}-{{item.ratio}}
            </div>
<!--            {{ form.enterprise }}-->
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              踏勘安排
            </template>
            {{ form.arrangement }}
          </el-descriptions-item>
          <el-descriptions-item :span="3">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              与转让相关的其他条件
            </template>
            <div v-html="form.transferCondition"></div>
          </el-descriptions-item>
          <el-descriptions-item :span="3">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              受让资格
            </template>
            <div v-html="form.assignability"></div>
          </el-descriptions-item>

        </template>
      </el-descriptions>
      <el-divider></el-divider>

    </div>
  </div>
</template>

<script>
import UploadService from "@/services/UploadFilesService";

export default {
  name: "JinChangJiaoYiOne",
  data () {
    return {
      isTextShow: false,
      activeNames: ['1', '2', '3'],
      form: {
        id: '',
        entryType: '',
        targetEnterprise: '',
        regPlace: '',
        targetName: '',
        disposalenterprise: '',
        projectno: '',
        overprice: '',
        state: '0',
        areacode: '',
        industryInvolved: '',
        mechanismExchange: '',
        entrustedMember: '',
        disclosureStartTime: '',
        disclosureEndTime: '',
        lxrName: '',
        lxrPhone: '',
        registration: '',
        legal: '',
        situation: '',
        establishmentTime: '',
        economicType: '',
        code: '',
        businessScope: '',
        preferential: '',
        placement: '',
        enterprise: '',
        financial: '',
        disclosure: '',
        transferorName: '',
        domicile: '',
        representative: '',
        registeredCapital: '',
        transferorTime: '',
        transferorEconomicType: '',
        transferorInvolved: '',
        transferorCode: '',
        transferorRatio: '',
        property: '',
        transferReservePrice: '',
        payment: '',
        transferConditions: '',
        assignability: '',
        arrangement: '',
        earnestMoney: '',
        // auditstate: "",
        deleted: '',
        createDate: '',
        updateDate: '',
        creator: '',
        updater: '',
        top: '',
        // showtype: "",
        shareList: [
          {
            id: '1',
            assId: '1',
            name: '1',
            ratio: '1'
          }
        ],
      },
      //标的企业基本情况
      enterpriseNameform: {
        enterpriseName: "中城信托有限责任公司",
      },
      enterpriseSituation: {
        domicile: "北京市东城区(北京市东城区安外大街2号)",
        spokesman: "安国勇",
        establishmentDate: "1995-11-20",
        registeredCapital: "485000.00万元",
        actualCapitalReceived: "485000.00万元",
        economicType: "国有控股企业",
        firmType: "有限责任公司",
        profession: "其他金融业",
        institutionCode: "91110000101219629L",
        scale: "中型",
        radius: "资产信托",
        isSurrender: "是",
        isparticipation: "否",
        issettle: "否",
        isTransfer: "否",
      },
      tableData: [
        {
          stockholderName: "中国人民保险集团股份有限公司",
          scale: 32.9206,
        },
        {
          stockholderName: "国华能源投资有限公司",
          scale: 20.3528,
        },
        {
          stockholderName: "其他股东",
          scale: 32.9206,
        },
        {
          stockholderName: "山东能源集团有限公司",
          scale: 32.9206,
        },
      ],
      dataForm: {},
      fileList: []
    };
  },
  activated () {
    console.log ('this.$route.query.id', this.$route.query.id)
    this.$set (this.dataForm, 'id', this.$route.query.id)
    this.getByid ()
  },
  mounted () {
  },
  methods: {
    goLink (url) {
      window.open (url, '_blank')
    },
    getDictLabel (val) {
      if (val == 0) {
        return '公示期'
      } else if (val == 10) {
        return '已撤牌'
      } else if (val == 20) {
        return '已成交'
      }
    },
    isClickText () {
      this.isTextShow = true;
    },
    packUp () {
      this.isTextShow = false;
    },
    getByid () {
      console.log ('我进场了')
      this.$fetch ('/shoppingui/ecentrytransaction/' + this.dataForm.id, {}, true).then ((data) => {
        console.log ('this.进场交易', data)
        if (data.code !== 0) {
          this.$message ({
            message: '详情获取失败',
            offset: 130,
            type: "error",
            showClose: "true",
          });
          return false;
        }
        this.form = {
          ...data.data
        }
        UploadService.getFilesa1 (this.form.entryfile).then ((response) => {
          this.fileList = response.data
          console.log(this.fileList)
        });
        console.log ('this.form进场交易', this.form)
      }).catch (() => {
      })
    },
    myurl(url) {
      if (url != null) {
        var reg = RegExp(/shoppingui/);
        if (reg.test(url)) {
          const index = url.indexOf("shoppingui")
          const res = this.$urldown + url.substring(index)
          return res
        }
      } else {
        return null
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/index.css";

/deep/ .descriptions-style {
  .el-descriptions__title {
    width: 100%;
  }

  .descriptions-style-title {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .el-descriptions-item__label {
    width: 150px;
  }
}
</style>
